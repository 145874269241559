
import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Injectable()
export class WebServiceInterceptor implements HttpInterceptor {
  reqCount: number = 0;
  constructor(private _toastr: ToastrService) { }

  /**
 * Add  token to request header for authorization.
 * @param headerValues 
 */
  private addAuthorizationHeader(headerValues: { [key: string]: string }) {
    const authToken = localStorage.getItem("token");
    if (authToken) {
      headerValues['Authorization'] = `Bearer ${authToken}`;
    }
  }

  /**
   * HTTP methods like PUT and DELETE is not supported by all servers.
   * X-HTTP-Method-Override header is added here.
   * @param headerValues 
   * @param req 
   * @returns 
   */
  private overwriteHttpMethod(
    headerValues: { [key: string]: string },
    req: HttpRequest<any>): HttpRequest<any> {
    req = req.clone({
      setHeaders: headerValues
    });

    return req;
  }


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headerValues: { [key: string]: string } = {};
    var loader: any = document.getElementById("showLoader");
    this.addAuthorizationHeader(headerValues);
    req = this.overwriteHttpMethod(headerValues, req);
    this.reqCount++;
    if (this.reqCount > 0) {
      loader.style.display = "block";
      document.body.style.overflow = "hidden";
    }

    return next.handle(req).pipe(
      catchError((event: any) => {
        this._toastr.error('Unable to connect to server!.');
        return of(event);
      }),
      finalize(() => {
        this.reqCount--;
        if (this.reqCount == 0) {
          loader.style.display = "none";
          document.body.style.overflow = "revert";
        }

      })
    );
  }
}
