<nav #navbar class="navbar active">
  <div class="container-fluid">
    <div class="navbar-header">
      <a href="#" onClick="return false;" class="navbar-toggle collapsed"
        (click)="isNavbarCollapsed = !isNavbarCollapsed" aria-expanded="false"></a>
      <a href="#" onClick="return false;" class="bars" (click)="mobileMenuSidebarOpen($event,'overlay-open')"></a>
      <a routerLink="dashboard/main" class="logo d-flex justify-content-center">
        <div class="d-flex headlogo">
        <img class="one" src="assets/images/logo-asset.svg"  width="40" height="">
        <img class="two" src="assets/images/logo-text.svg"  width="105" height="">
        </div>
        <!-- <img src="assets/images/logo-asset.png" width="230" height="50">
        <img src="assets/images/logo-text.png" width="230" height="50" class="ml-3"> -->
    </a>
    </div>
    <div class="collapse navbar-collapse" [ngbCollapse]="isNavbarCollapsed">
      <ul class="pull-left collapse-menu-icon">
        <li>
          <button mat-icon-button (click)="callSidemenuCollapse()" class="sidemenu-collapse">
            <mat-icon>menu</mat-icon>
          </button>
        </li>
      </ul>
      <ul class="nav navbar-nav navbar-right">
        <!-- Full Screen Button -->
        <li class="fullscreen">
          <button mat-icon-button (click)="callFullscreen()" class=" nav-notification-icons">
            <mat-icon>fullscreen</mat-icon>
          </button>
        </li>
        <!-- #END# Full Screen Button -->
        <!-- #START# Notifications-->
        <!-- <li class="nav-item btnNotification" ngbDropdown>
          <button mat-icon-button ngbDropdownToggle class="nav-notification-icons">
            <mat-icon>notifications_none</mat-icon>
            <span class="label-count bg-orange"></span>
          </button>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 250px;" [perfectScrollbar]>
              <ul class="menu">
                <li>
                  <a href="#" *ngFor="let notification of notifications" onClick="return false;">
                    <span class="table-img msg-user">
                      <img src="{{notification.userImg}}" alt="">
                    </span>
                    <span class="menu-info">
                      <span class="menu-title">{{notification.userName}}</span>
                      <span class="menu-desc">
                        <i class="material-icons">access_time</i> {{notification.time}}
                      </span>
                      <span class="menu-desc">{{notification.message}}</span>
                    </span>
                  </a>
                </li>
              </ul>
            </div>
            <a class="text-center d-block border-top py-1">Read
              All Notifications</a>
          </div>
        </li> -->
        <!-- #END# Notifications-->
        <li class="nav-item user_profile" ngbDropdown>
          <div class="chip dropdown-toggle" ngbDropdownToggle class="nav-notification-icons pt-0">
            <img src="assets/images/user.jpg" class="rounded-circle" width="32" height="32" alt="User">
            <span>{{userName}}</span>
          </div>
          <div ngbDropdownMenu class="notification-dropdown pullDown">
            <div class="noti-list" style="position: relative; max-width: 600px; max-height: 300px;" [perfectScrollbar]>
              <ul class="menu">
                <li class="body">
                  <ul class="user_dw_menu">
                  
                    <li>
                      <a routerLink="/extra-pages/profile" onClick="return false;">
                        <mat-icon aria-hidden="false" class="msr-2">person</mat-icon>Profile
                      </a>
                    </li>
                  
                    <li>
                      <a href="javascript:void(0);" (click)="delete(deleteRecord)">
                        <mat-icon aria-hidden="false" class="msr-2">power_settings_new</mat-icon>Logout
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <!-- #END# Tasks -->
      </ul>
    </div>
  </div>
</nav>

<ng-template #deleteRecord let-modal>
  <div class="editRowModal_all">
    <div class="modal-header editRowModal">
      <h4 class="modal-title" id="modal-basic-title1">
        <div class="modal-header ">

          <div class="modal-about">
            <div class="font-weight-bold p-t-10 font-17">
              Logout</div>
          </div>
        </div>
      </h4>
      <button type="button" class="close" id='close-contract' aria-label="Close"
        (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true"><i class="material-icons">close</i></span>
      </button>
    </div>
    <div class="modal-body ">

      <div class="mb-0">
        <div class="card-body">
          <img class="one" src="assets/images/logo-asset.svg"  width="40" height="">
          <img class="two" src="assets/images/logo-text.svg"  width="105" height="">
          <div class="m-4 ">
            <p class="card-title">Are you sure you want to logout from vendor panel?</p>
          </div>

          <div class="modal-footer py-0">
            <button mat-raised-button class="btn mr-2 btn-secondary"
              (click)="modal.dismiss('Cross click')">No</button>
            <button mat-raised-button class="btn btn-danger" (click)="logout()">Yes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
